import { directive } from "@babel/types";
import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import Footer from "./Footer";
import video1 from "../assets/video.mp4";
import "../App.css";
//JSX

export default function Thankyou() {
  const { state } = useLocation();
  const { message } = state != null ? state : { message: "Server Error, try again..." }; // Read values passed on state


  return (
      <div className="App">
      <div className="flex flex-row justify-between pt-4 pb-4">
        <p>01.07.23</p>
        <p>Basketball Turnier am Volkstheather</p>
        <p>14:00</p>
      </div>
        <div className="flex flex-col">
          <p className="Message">{message}</p>
          <div className="flex justify-items-center items-center video-container">
            <video className='videoHome' autoPlay={true} loop={true} controls={false} playsInline muted >
              <source src={video1}></source>
            </video>
          </div>
          <Footer />
        </div>
      </div>
  );
}
