import { collection, getDocs, serverTimestamp, orderBy, query } from "firebase/firestore";
import { db } from "../config/firebase";

export const getTeams = async (mod,collection) => {
    try{
      const data = await getDocs(query(collection, orderBy('Date','asc')));
      mod(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
    }catch(err){
      console.error(err);
    }
  };