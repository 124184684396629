import { directive } from "@babel/types";
import React, { useState, useEffect } from "react";
import { db } from "../config/firebase";
import { collection, addDoc, getCountFromServer, serverTimestamp  } from "firebase/firestore";
import { useNavigate } from "react-router-dom";
import "../App.css";
//JSX

export default function Signin() {

  const [newTeamname, setNewTeamname] = useState("");
  const [newMember1, setNewMember1] = useState("");
  const [newMember2, setNewMember2] = useState("");
  const [newMember3, setNewMember3] = useState("");
  const [newMember4, setNewMember4] = useState("");
  const [newKontakt, setNewKontakt] = useState("");
  const [newAccept, setNewAccept] = useState("");
  const [countColl, setCountColl] = useState("");
  const navigate = useNavigate();

  const teamsCollectionRef = collection(db, "Teams");

  useEffect(() =>{
    const getCount = async () => {    
      const snapshot = await getCountFromServer(teamsCollectionRef);
      setCountColl(snapshot._data.count.integerValue); 
    console.log(snapshot._data.count.integerValue)
    };
    getCount();
    
  })

  const createTeam = async (e) => {
    e.preventDefault();
    try{
      await addDoc(teamsCollectionRef, {
        TeamName: newTeamname,
        Member1: newMember1,
        Member2: newMember2,
        Member3: newMember2,
        Member4: newMember4,
        Kontakt: newKontakt,
        Accept: newAccept,
        Date:  serverTimestamp(),
      })
    }catch(error){
      navigate('/thankyou', {state:{message: 'There is a problem with server...'}});
    }
    
    document.getElementById("Signin").reset();
    navigate('/thankyou', { state: {message: 'Danke für die Anmeldung ツ'}});
  };

  if(countColl == ""){
       
    <div className="flex">
    <div className="w-1/2 max-md:w-0"></div>

    <div className="w-1/2 max-md:w-full inputText">
      <p>Anmeldung</p>
      <p>.  .  .</p>
    </div>
  </div>
  }else if(countColl < 20){
    return (
      <div className="flex">
        <div className="w-1/2 max-md:w-0"></div>
  
        <div className="w-1/2 max-md:w-full inputText">
          <p>Anmeldung</p>
          <form id="Signin" onSubmit={createTeam}>
            <input
              type="text"
              name="TeamName"
              id="TeamName"
              placeholder="Team Name*"
              required="required"
              onChange={(event) => {
                setNewTeamname(event.target.value);
              }}
            />
            <input
              type="text"
              name="Member1"
              id="Member1"
              placeholder="Member 1*"
              required="required"
              onChange={(event) => {
                setNewMember1(event.target.value);
              }}
            />
            <input
              type="text"
              name="Member2"
              id="Member2"
              placeholder="Member 2*"
              required="required"
              onChange={(event) => {
                setNewMember2(event.target.value);
              }}
            />
            <input type="text"
              name="Member3"
              id="Member3"
              placeholder="Member 3*"
              required="required"
              onChange={(event) => {
                setNewMember3(event.target.value);
              }}
            />
            <input
              type="text"
              name="Member4"
              id="Member4"
              placeholder="Member 4*"
              required="required"
              onChange={(event) => {
                setNewMember4(event.target.value);
              }}
            />
            <input
              type="email"
              name="Kontakt"
              id="Kontakt"
              placeholder="Kontakt*"
              required="required"
              onChange={(event) => {
                setNewKontakt(event.target.value);
              }}
            />
            <input
              type="checkbox"
              required="required"
              name="Accetto"
              id="Accetto"
              value="true"
              onChange={(event) => {
                setNewAccept(event.target.value);
              }}
            /><label>Das musst du bestätigen, damit du teilnehmen kannst.</label>
  
            <button type="submit">Anmeldung</button>
          </form>
        </div>
      </div>
    );
  }else{
    return(
       
      <div className="flex">
        <div className="w-1/2 max-md:w-0"></div>
  
        <div className="w-1/2 max-md:w-full inputText">
          <p>Anmeldung</p>
          <p style={{color: "red"}}>Es sind leider keine Spots mehr frei :(</p>
        </div>
      </div>
    )
  }
  
}
