import React from "react";
//JSX




export default function Footer() {


  return(
    <div className="flex flex-row max-md:flex-col justify-between pt-10 pb-10 mt-20" >
    <p>© 2023, Volks</p>
    <p>volks3v3@gmail.com</p>
    <p>Imprint & Privacy</p>
  </div>
  );
}