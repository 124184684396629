import React from "react";
import Video from "./Video";
import Signin from "./Signin";
import IGFeed from "./IGFeed.tsx";
import About from "./About";
import Footer from "./Footer";
import '../App.css';

//JSX

export default function Home() {

  return (
    <div className="App">
      <Video />
      <About />
      <Signin />
      <IGFeed />
      <Footer />
    </div>
  );
}
