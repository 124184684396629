import { directive } from "@babel/types";
import React, { useState, useEffect } from "react";
import { InstaItem } from '../utils/InstaItem';
import Skeleton from '@mui/material/Skeleton';
import InstaGrid from "./InstaGrid.tsx";
import { dividerClasses } from "@mui/material";
//JSX

const user_id = "6452187921511589"
const access_token = "IGQVJVb3dJc0gxdEZA4ODlPeVMtQ3VacDFmdWFUUnlmNkp0STZAfSl81anRfVVcxUkl1bmoyRkgtdm9RVWU1bkpEb0g2TUdVR2hPVDFkYXlWUzVaMVFuN1lteGFxODY3NlcwV2JoRnVLclB5b2NVVkRNOAZDZD";
const instaUrl = `https://graph.instagram.com/${user_id}/media?access_token=${access_token}`




interface InstaGridProps{
  items: InstaItem[];
}




export default function IGFeed() {
  const [instaItems, setInstaItems] = useState<InstaItem[]>([]);

  useEffect(()=>{
    
    const fetchMedia = async (id: string) => {
      const mediaUrl = `https://graph.instagram.com/${id}?access_token=${access_token}&fields=media_url,permalink`
      
      const res = await fetch(mediaUrl);
      const json = (await res.json());

      const instaItem: InstaItem = {
        permalink: json.permalink,
        mediaUrl: json.media_url
      };

      return instaItem;
    }

    const doFetch = async () => {
      if(!user_id || !access_token){
        console.log('Userid or accesstoken is undefined ')
      return;
      }
      const res = await fetch(instaUrl);
      const json = (await res.json()).data;

      const fetchedItems: InstaItem[] = [];

      for(let i=0; i<json.length && i<3; i++){
        const item = json[i];
        const itemId = item.id;

        const instaItem = await fetchMedia(itemId);
        fetchedItems.push(instaItem);
      }

      setInstaItems(fetchedItems);
    }

    doFetch();


  }, [user_id, access_token, instaUrl])

  return(
    <div className="flex flex-col mt-40">
      <div className="flex flex-row max-md:flex-col space-between">
        <p>Alle Updates und Informationen findest du auf Instagram.</p>
        <a target="_blank" href='https://www.instagram.com/volksbasketball/'>@volksbasketball</a>
      </div>
      <div>
      <InstaGrid items={instaItems} />
      </div>
    </div>
    
  );
}