import { directive } from "@babel/types";
import React, { useState, useEffect } from "react";
import { InstaItem } from '../utils/InstaItem';
import Skeleton from '@mui/material/Skeleton';
//JSX


const user_id = process.env.IG_USER_ID
const access_token = process.env.IG_ACCESS_CODE;
const instaUrl = `https://graph.instagram.com/${user_id}/media?access_token=${access_token}`




interface InstaGridProps{
  items: InstaItem[];
}




export default function InstaGrid({ items }: InstaGridProps) {

  if(items.length === 0){
    return(

      <div className="bg-black">
        {(new Array(9)).fill(<Skeleton variant='rectangular' />)}
      </div>
     );
  }
  return(

   <div className="flex flex-row max-md:flex-col space-between">
    {items.map(function(item){
      if((item.mediaUrl).match(/\.webp|.jpg|.png|.jpeg/)){
        return(<img className="mediaFeed"  src={item.mediaUrl} key={item.mediaUrl}/>)
      }else if((item.mediaUrl).match(/\.mp4/)){
        return(<video className="mediaFeed" autoPlay={true} loop={true} controls={false} playsInline muted>
        <source src={item.mediaUrl} key={item.mediaUrl}></source>
      </video>)
      }
     
    })}
   </div>
  );
}