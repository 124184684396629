import React from "react";
import video1 from "../assets/video.mp4";
import logo from "../assets/logo.svg";
//JSX

export default function Video() {
  return (
    <div className="flex flex-col">      
      <img src={logo} className="logo"></img>
      <div className="flex justify-items-center items-center video-container">
        <video className='videoHome' autoPlay={true} loop={true} controls={false} playsInline muted>
          <source src={video1}></source>
        </video>
      </div>
      <div className="flex flex-row max-md:flex-col justify-between mt-4" >
        <p>01.07.23</p>
        <p>Basketball Turnier am Volkstheather</p>
        <p>14:00</p>
      </div>
    </div>
  );
}
