import React from "react";
import "../App.css";

//JSX

export default function Signin() {
  return (
    <div className="flex mt-28 mb-28">
      <div className="w-1/2  max-md:w-0"></div>

      <div className="w-1/2 max-md:w-full Text">
        <p className="About">Am 1. Juli ist es wieder soweit:</p>
        <br/>
        <p className="About">
          Das Volks 3v3 Turnier im Weghuberpark geht in die 2. Runde. Wie
          letztes Jahr gibt es 16 Teams a 4 Personen, von denen jeweils eine die
          Bank warmhält. Anmeldegebühr pro Person sind 10€ (alle Einnahmen
          werden in das Turnier investiert). Infos zu den Regeln gibts auf IG.
        </p>
        <br/>
        <p className="About">
          Neben dem außerordentlichen Privileg, sich für ein Jahr Volks-3v3
          Champ nennen zu können, und Team „TEAM“ den Titel abzunehmen, gibts
          natürlich dieses Jahr auch wieder juicy prizes. Genaue Infos dazu
          haben wir leider noch nicht, wir halten euch aber über IG auf dem
          laufenden :)
        </p>
        <br/>
        <p className="About">Die Anmeldung startet jetzt!</p>
      </div>
    </div>
  );
}
