import React from "react";
import Home from "./components/Home";
import ViewTeam from "./components/ViewTeam";
import Marquee from "react-fast-marquee";
import TurnierAn from "./assets/Turnier_anmeldung.png";
import Thankyou from "./components/Thankyou";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import ScrollToTop  from './utils/scroll';
 
//JSX


function App() {
  return (
    <div className="bg-fixed bg-gradient-to-b from-green-custom from-1% to-black to-15%">
    <BrowserRouter>
    <ScrollToTop />
    <Routes>

      <Route path="/" element={<><Marquee speed="150" className="flex items-center space-between ">
      <div className="marquee-item-desk ">
        <img src={TurnierAn} />
      </div>
      <div className="marquee-item-desk ">
        <img src={TurnierAn} />
      </div>
      <div className="marquee-item-desk ">
        <img src={TurnierAn} />
      </div>
      <div className="marquee-item-desk ">
        <img src={TurnierAn} />
      </div>
      <div className="marquee-item-desk ">
        <img src={TurnierAn} />
      </div>
    </Marquee><Marquee speed="30" className="flex items-center space-between">
      <div className="marquee-item-mob ">
        <img src={TurnierAn} />
      </div>
      <div className="marquee-item-mob ">
        <img src={TurnierAn} />
      </div>
      <div className="marquee-item-mob ">
        <img src={TurnierAn} />
      </div>
      <div className="marquee-item-mob ">
        <img src={TurnierAn} />
      </div>
      <div className="marquee-item-mob ">
        <img src={TurnierAn} />
      </div>
    </Marquee><Home /></>}>  </Route>     
        <Route path="/viewteams" element={<ViewTeam />} />
        <Route path="/Thankyou" element={<Thankyou />} />
    </Routes>
  </BrowserRouter>
  </div>



  )
}

export default App;
