import { directive } from "@babel/types";
import React, { useState, useEffect } from "react";
import "../App.css";
import { db } from "../config/firebase";
import { collection, getDocs, serverTimestamp } from "firebase/firestore";
import { getTeams } from '../utils/get';
//JSX

export default function ViewTeam() {
  //     // collection ref
  //     const colRef = collection(db, 'books')

  //     // get collection data
  //     getDocs(colRef)
  //         .then((snapshot)=>{
  //             console.log(snapshot.docs)
  //         })

  const [teams, setTeams] = useState([]);
  const teamsCollectionRef = collection(db, "Teams");

  useEffect(() => {
    getTeams(setTeams, teamsCollectionRef);
  }, []);

  let i = 0;
  if(teams != [] && teams.length > 0){
    return (
      <div className="bg-black flex min-w-full h-screen grid justify-items-center place-items-center">
          <table className="bg-black tabella-team">
            <thead>
              <tr>
                <th>N°</th>
                <th>Nome Team</th>
                <th>Member1</th>
                <th>Member2</th>
                <th>Member3</th>
                <th>Member4</th>
                <th>Kontakt</th>
                <th>Date Sign in</th>
              </tr>
            </thead>
            {teams.map((team) => {
              i++;
              const output = new Date(team.Date.seconds* 1000);
              return (
                <tbody>
                  <tr>
                  <td>{i}</td>
                    <td>{team.TeamName}</td>
                    <td>{team.Member1}</td>
                    <td>{team.Member2}</td>
                    <td>{team.Member3}</td>
                    <td>{team.Member4}</td>
                    <td>{team.Kontakt}</td>
                    <td>{output.toString()}</td>
                  </tr>
                </tbody>
              );
            })}
          </table>
      </div>
    );
  }else{
    return(
      <div className="bg-black flex min-w-full h-screen grid justify-items-center place-items-center">
        <h1>You are not authenticated.</h1>
      </div>
    )
  }

}
